import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import {
  API_BASE_URL,
  APP_PREFIX_PATH,
  LIVE_URL,
  REACT_APP_DEV_MODE,
  TEST_URL,
  TEST_USER_URL,
} from "configs/AppConfig";

import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";

import classes from "./SignInForm.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Input,
  message,
  Button,
  Form,
  notification,
  Popconfirm,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  auth,
  facebookAuthProvider,
  googleAuthProvider,
} from "configs/FirebaseConfig";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  updateEmail,
  FacebookAuthProvider,
  reauthenticateWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import getErrorMessage from "helpers/getErrorMessage";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const rules = {
  name: [
    {
      required: true,
      message: "Required",
    },
  ],
  email: [
    {
      required: true,
      message: "Required",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  required: [
    {
      required: true,
      message: "Required",
    },
  ],
};

const SignInForm = ({
  isPage = false,
  redirect = false,
  closeModal,
  redirectRoute = "",
}) => {
  const [facebookForm] = Form.useForm();
  const [authError, setAuthError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { direction } = useSelector((state) => state.language);
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);
  const [showLoginWithEmail, setShowLoginWithEmail] = useState(false);
  const [loadingEmailLoginLink, setLoadingEmailLoginLink] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [recaptchaVerifySuccess, setRecaptchaVerifySuccess] = useState(null);
  const [recaptchaCheckLoading, setRecaptchaCheckLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const handleLogout = async () => {
    await signOut(auth);
    dispatch(userActions.logout());
    notification.error({
      message: t("error"),
      description: "Unauthorized.",
    });
    navigate("/");
    return;
  };

  // Function to load reCAPTCHA script dynamically
  const loadRecaptchaScript = () => {
    return new Promise((resolve, reject) => {
      if (
        document.querySelector(
          `script[src="https://www.google.com/recaptcha/api.js?render=${
            REACT_APP_DEV_MODE == "Dev"
              ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
              : REACT_APP_DEV_MODE == "userDev"
              ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_USER_TEST
              : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
          }"]`
        )
      ) {
        resolve(); // Script is already loaded
      } else {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${
          REACT_APP_DEV_MODE == "Dev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
            : REACT_APP_DEV_MODE == "userDev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_USER_TEST
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
        }`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  };

  const recaptchaVerification = async () => {
    if (window.grecaptcha) {
      try {
        // Generate the reCAPTCHA v3 token
        const token = await window.grecaptcha.execute(
          REACT_APP_DEV_MODE == "Dev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
            : REACT_APP_DEV_MODE == "userDev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_USER_TEST
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE,
          { action: "signin" }
        );
        setRecaptchaToken(token);
        setRecaptchaCheckLoading(true);

        // Verify the token with your backend
        const tokenResponse = await fetch(`${API_BASE_URL}recaptcha/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        const tokenResult = await tokenResponse.json();

        if (tokenResult.success) {
          setRecaptchaCheckLoading(false);
          setRecaptchaVerifySuccess(true);
        } else {
          setRecaptchaCheckLoading(false);
          setRecaptchaVerifySuccess(false);
        }
      } catch (error) {
        setRecaptchaCheckLoading(false);
        setRecaptchaVerifySuccess(false);
        console.error("Error during sign-in:", error);
      }
    } else {
      notification.error({
        message: "Error",
        description: "reCAPTCHA is not loaded. Please try again later.",
      });
      await loadRecaptchaScript();
    }
  };

  useEffect(() => {
    recaptchaVerification();
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (recaptchaVerifySuccess == false || !recaptchaVerifySuccess) {
      //can't login if recaptcha failed
      notification.error({
        message: "Error",
        description: "reCAPTCHA check failed.",
      });
      return;
    }
    setLoading(true);
    setAuthError(null);

    if (!email || !password) {
      notification.error({
        message: t("error"),
        description: t("please_fill_all_required_fields"),
        style: { direction },
      });
      setLoading(false);
      return;
    }
    console.log("authInfo", email, password);

    try {
      // send request to the API with email & password
      // if response correct
      console.log("auth 222", auth);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("user data 222", userCredential);
      const user = userCredential.user;
      console.log("user 222", user);

      const idTokenResult = await user.getIdTokenResult();
      const userType = idTokenResult.claims.userType;

      //logging out the user if the userType is not customer
      if (userType != "Customer") {
        await signOut(auth);
        dispatch(userActions.logout());
        setLoading(false);
        notification.error({
          message: "Error",
          description: "Invalid login.",
        });
        return;
      }

      const rememberMe = e.target.rememberMe.checked;

      if (rememberMe) {
        await setPersistence(auth, browserLocalPersistence); //to remember the user across browser sessions
      } else {
        await setPersistence(auth, browserSessionPersistence); //to remember the user only within the current session
      }

      notification.success({
        message: t("success"),
        description: "Login successful.",
        style: { direction },
      });

      // const userDetails = {
      //   displayName: user.displayName,
      //   email: user.email,
      //   accessToken: user.accessToken,
      //   emailVerified: user.emailVerified,
      //   phoneNumber: user.phoneNumber,
      //   refreshToken: user.refreshToken,
      //   photoURL: user.photoURL,
      // };

      // dispatch(
      //   userActions.login({ user: userDetails, accessToken: user.accessToken })
      // );
      setLoading(false);
      if (isPage) {
        navigate(redirect ? redirectRoute : "/");
      } else {
        closeModal();
      }
      // dispatch(modalActions.toggleModal({ show: false }));
    } catch (error) {
      console.log("login error", error);
      setLoading(false);
      notification.error({
        message: "Error",
        description: getErrorMessage(error.code),
      });
    }
  };

  const shiftToSignup = () => {
    if (location.pathname.includes("login")) {
      navigate(`/signup?redirectRoute=${redirectRoute}`);
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signup",
        })
      );
    }
  };

  const signInWithGoogle = async () => {
    if (recaptchaVerifySuccess == false || !recaptchaVerifySuccess) {
      notification.error({
        message: "Error",
        description: "reCAPTCHA check failed.",
      });
      return;
    }
    setGoogleLoading(true);
    try {
      //logging in to firebase using gmail account
      const result = await signInWithPopup(auth, googleAuthProvider);
      console.log("user data 222", result.user);
      const user = result.user;

      const idTokenResult = await user.getIdTokenResult();
      const userType = idTokenResult.claims.userType;

      //to remember the user only within the current session
      await setPersistence(auth, browserSessionPersistence);

      //checking the user data available in our server
      const response = await fetch(`${API_BASE_URL}users/UserByFirebaseId`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const data = await response.json();
      console.log("data from user checking api", data);

      if (data.statusCode == 401) {
        handleLogout();
      }

      if (data.success) {
        //checking the user is customer or not
        if (userType != "Customer") {
          await signOut(auth);
          notification.error({
            message: "Error",
            description: "Invalid login.",
          });
          setGoogleLoading(false);
          return;
        }
        notification.success({
          message: t("success"),
          description: "Login successful.",
          // style: { direction },
        });
        setGoogleLoading(false);
        if (isPage) {
          navigate(redirect ? redirectRoute : "/");
        } else {
          closeModal();
        }
      } else if (data.statusCode == 404) {
        //case of user not in our server, which means user is registering using gmail account
        //so creating the new user in server using gmail account details
        const sendingValues = {
          firstName: user.displayName?.replace(/[^a-zA-Z0-9]/g, ""),
          email: user.email,
          customerType: "individual",
          // phoneNumber: user.phoneNumber,
        };
        console.log("sendingVAlues", sendingValues);

        const res = await fetch(`${API_BASE_URL}users/register-firebase-user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify(sendingValues),
        });

        const dataRes = await res.json();
        console.log("data from server api", dataRes);

        if (dataRes.statusCode == 401) {
          handleLogout();
        }

        if (!res.ok) {
          console.log("Error", dataRes);
          await signOut(auth); //to avoid logged in condition from firebase eventhough user create api failed
          if (Array.isArray(dataRes.message)) {
            dataRes.message.forEach((error) => {
              notification.error({
                message: t("error"),
                description: error,
              });
            });
          } else {
            notification.error({
              message: t("error"),
              description: dataRes.message
                ? dataRes.message
                : "An error occured.",
            });
          }
          setGoogleLoading(false);
          return;
        }
        if (dataRes.success) {
          //refresh token after new user registered to get all data in token
          const idTokenResult = await auth.currentUser.getIdToken(true);
          console.log("id token 333", idTokenResult);
          dispatch(userActions.updateAccessToken(idTokenResult));
          notification.success({
            message: t("success"),
            description: "User created successfully.",
            style: { direction },
          });
          if (isPage) {
            navigate(redirect ? redirectRoute : "/");
          } else {
            closeModal();
          }
        }
      } else if (data.statusCode == 409) {
        //conflict situation of some user details already there in another user data
        await signOut(auth);
        dispatch(userActions.logout());
        notification.error({
          message: t("error"),
          description: "Cannot login.",
        });
      }

      //checking the error of user GET api
      if (!response.ok) {
        console.log("Error", data);
        if (data.statusCode == 404) {
          return;
        }
        await signOut(auth); //to avoid logged in condition from firebase eventhough user create api failed
        if (Array.isArray(data.message)) {
          data.message.forEach((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        } else {
          notification.error({
            message: t("error"),
            description: data.message ? data.message : "An error occured.",
          });
        }
        setGoogleLoading(false);
        return;
        // throw new Error(data.message);
      }

      setGoogleLoading(false);
    } catch (error) {
      setGoogleLoading(false);
      if (error.statusCode == 404) {
        return;
      }
      notification.error({
        message: "Error",
        description: getErrorMessage(error.code),
      });
      console.error("Error signing in with Google: ", error);
    }
  };

  const checkEmailVerification = async (user) => {
    try {
      await user.reload(); // Refresh the user data
      return user.emailVerified; // Return whether email is verified or not
    } catch (error) {
      return false;
    }
  };

  const waitForEmailVerification = async (user) => {
    let isVerified = false;
    notification.info({
      message: "Verify Your Email",
      description: "Please check your inbox and verify your email.",
    });

    // Poll for verification status every few seconds
    while (!isVerified) {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
      isVerified = await checkEmailVerification(user);

      if (isVerified) {
        notification.success({
          message: "Email verified",
          description: "Your email has been successfully verified.",
        });
        return true;
      }
    }
  };

  const signInWithFacebook = async () => {
    if (recaptchaVerifySuccess == false || !recaptchaVerifySuccess) {
      notification.error({
        message: "Error",
        description: "reCAPTCHA check failed.",
      });
      return;
    }
    setFacebookLoading(true);
    try {
      //logging in to firebase using gmail account
      const result = await signInWithPopup(auth, facebookAuthProvider);
      const user = result.user;

      let email = user.email;

      const idTokenResult = await user.getIdTokenResult();
      const userType = idTokenResult.claims.userType;

      //to remember the user only within the current session
      await setPersistence(auth, browserSessionPersistence);

      //checking the user data available in our server
      const response = await fetch(`${API_BASE_URL}users/UserByFirebaseId`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const data = await response.json();
      console.log("data from user checking api", data);

      if (data.statusCode == 401) {
        handleLogout();
      }

      if (data.success) {
        //checking the user is customer or not
        if (userType != "Customer") {
          await signOut(auth);
          notification.error({
            message: "Error",
            description: "Invalid login.",
          });
          setFacebookLoading(false);
          return;
        }
        notification.success({
          message: t("success"),
          description: "Login successful.",
          // style: { direction },
        });
        setFacebookLoading(false);
        if (isPage) {
          navigate(redirect ? redirectRoute : "/");
        } else {
          closeModal();
        }
      } else if (data.statusCode == 404) {
        //case of user not in our server, which means user is registering using gmail account
        //so creating the new user in server using gmail account details

        let emailFromInput = false;

        // Check if email is missing in facebook response
        if (!email) {
          // Prompt for email using Popconfirm - for getting email from client
          email = await promptForEmail();

          if (email) emailFromInput = true;
          if (!email) {
            notification.error({
              message: "Error",
              description: "Email is required for Facebook login.",
            });
            setFacebookLoading(false);
            await signOut(auth);
            return;
          }
        }

        const sendingValues = {
          firstName: user.displayName?.replace(/[^a-zA-Z0-9]/g, ""),
          email: email,
          customerType: "individual",
          // phoneNumber: user.phoneNumber,
        };

        const res = await fetch(`${API_BASE_URL}users/register-firebase-user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify(sendingValues),
        });

        const dataRes = await res.json();

        if (dataRes.statusCode == 401) {
          handleLogout();
        }

        if (!res.ok) {
          console.log("Error", dataRes);
          await signOut(auth); //to avoid logged in condition from firebase eventhough user create api failed
          if (Array.isArray(dataRes.message)) {
            dataRes.message.forEach((error) => {
              notification.error({
                message: t("error"),
                description: error,
              });
            });
          } else {
            notification.error({
              message: t("error"),
              description: dataRes.message
                ? dataRes.message
                : "An error occured.",
            });
          }
          setFacebookLoading(false);
          return;
        }
        if (dataRes.success) {
          //first facebook login, if email not given by facebook and asked from client, then
          // after updating that token gets revoked and needs to login again
          if (emailFromInput) {
            notification.success({
              message: t("success"),
              description:
                "Account created successfully. Please log in again due to the email update.",
              style: { direction },
            });
            setFacebookLoading(false);
            await signOut(auth);
            dispatch(userActions.logout());
          } else {
            //refresh token after new user registered to get all data in token
            const idTokenResult = await auth.currentUser.getIdToken(true);
            console.log("id token 333", idTokenResult);
            dispatch(userActions.updateAccessToken(idTokenResult));
            notification.success({
              message: t("success"),
              description: "User created successfully.",
              style: { direction },
            });
            if (isPage) {
              navigate(redirect ? redirectRoute : "/");
            } else {
              closeModal();
            }
          }
        }
      } else if (data.statusCode == 409) {
        //conflict situation of some user details already there in another user data
        await signOut(auth);
        dispatch(userActions.logout());
        notification.error({
          message: t("error"),
          description: "Cannot login.",
        });
      }

      //checking the error of user GET api
      if (!response.ok) {
        console.log("Error", data);
        if (data.statusCode == 404) {
          return;
        }
        await signOut(auth); //to avoid logged in condition from firebase eventhough user create api failed
        if (Array.isArray(data.message)) {
          data.message.forEach((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        } else {
          notification.error({
            message: t("error"),
            description: data.message ? data.message : "An error occured.",
          });
        }
        setFacebookLoading(false);
        return;
        // throw new Error(data.message);
      }

      setFacebookLoading(false);
    } catch (error) {
      setFacebookLoading(false);
      if (error.statusCode == 404) {
        return;
      }
      notification.error({
        message: "Error",
        description: `${getErrorMessage(error.code)}`,
      });
      console.error("Error signing in with Google: ", error);
    }
  };

  const promptForEmail = () => {
    return new Promise((resolve) => {
      let modal = null;

      const handleOk = async ({ modalResolve, modalReject }) => {
        facebookForm
          .validateFields()
          .then((values) => {
            if (values.email) {
              resolve(values.email); // Resolve promise with email
              modal.destroy(); // Close the modal after email is valid
              facebookForm.resetFields();
              modalResolve(); //manual call to close modal
            }
          })
          .catch((info) => {
            modalReject(); //to stop automatic closing of modal on calling onOk function
            return;
          });
      };

      const handleCancel = () => {
        resolve(null); // Resolve null if user cancels
        modal.destroy(); // Close the modal
      };

      modal = Modal.confirm({
        title: "Email Required for Facebook Login",
        content: (
          <Form form={facebookForm} layout="vertical">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Please enter your email" />
            </Form.Item>
          </Form>
        ),
        okText: "Submit",
        cancelText: "Cancel",
        // onOk: handleOk,
        onOk: () =>
          new Promise((resolve, reject) => {
            handleOk({ modalResolve: resolve, modalReject: reject });
          }),
        onCancel: handleCancel,
      });
    });
  };

  const forgotPasswordSendLink = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (values.email) {
          try {
            setLoadingForgotPassword(true);
            const config = {
              url:
                REACT_APP_DEV_MODE == "Dev" //TODO
                  ? TEST_URL
                  : REACT_APP_DEV_MODE == "userDev"
                  ? TEST_USER_URL
                  : LIVE_URL, //redirect url after resetting password
              handleCodeInApp: true, //reset password link is handled in website itself
            };
            await sendPasswordResetEmail(auth, values.email, config);
            notification.success({
              message: t("success"),
              description:
                "Your password reset has been submitted, and if your email is on file, we will send you a password reset link.",
              style: { direction },
            });
            dispatch(modalActions.toggleModal());
            setLoadingForgotPassword(false);
          } catch (error) {
            console.error("Error sending password reset email:", error);
            notification.error({
              message: "Error",
              description: getErrorMessage(error.code),
            });
            setLoadingForgotPassword(false);
            return;
          }
        }
      })
      .catch((info) => {
        console.log("error in form", info);
        message.error("Please check all input fields");
      });
  };

  const sendLoginWithEmailLink = () => {
    if (recaptchaVerifySuccess == false || !recaptchaVerifySuccess) {
      notification.error({
        message: "Error",
        description: "reCAPTCHA check failed.",
      });
      return;
    }

    emailForm.validateFields().then(async (values) => {
      setLoadingEmailLoginLink(true);
      const actionCodeSettings = {
        url:
          REACT_APP_DEV_MODE == "Dev" //TODO
            ? TEST_URL
            : REACT_APP_DEV_MODE == "userDev"
            ? TEST_USER_URL
            : LIVE_URL, //redirect url after resetting password
        handleCodeInApp: true,
      };

      try {
        await sendSignInLinkToEmail(auth, values.email, actionCodeSettings);
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", values.email);
        notification.success({
          message: t("success"),
          description:
            "Email verification notification has been sent. Please also check your spam folder and unblock Oumuamua.news safe sender list.",
          // description: "Login link send to your email.",
          style: { direction },
        });
        dispatch(modalActions.toggleModal());
        setLoadingEmailLoginLink(false);
      } catch (error) {
        console.error("Error sending email link:", error);
        notification.error({
          message: "Error",
          description: getErrorMessage(error.code),
        });
        setLoadingEmailLoginLink(false);
      }
    });
  };

  const goBack = () => {
    if (showForgotPassword) {
      setShowForgotPassword(false);
      form.resetFields();
    }
    if (showLoginWithEmail) {
      setShowLoginWithEmail(false);
      emailForm.resetFields();
    }
  };

  return (
    <div dir={direction} className="show-recaptcha">
      {!showForgotPassword && !showLoginWithEmail && (
        <>
          <form
            // className={classes.signinForm}
            className={`${classes.signinForm} connectForm`}
            onSubmit={(e) => onSubmitHandler(e)}
          >
            <h1>{t("login_to_your_account")}</h1>
            <div className={classes.inputField}>
              <span className={classes.inputLabel}>{t("email")}</span>
              <Input
                placeholder={t("email")}
                className={classes.signInInput}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // required
              />
            </div>
            <div className={classes.inputField}>
              <span className={classes.inputLabel}>{t("password")}</span>
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("password")}
                className={classes.signInInput}
                // required
              />
            </div>
            <div className={classes.signinOptions}>
              <CheckboxInput
                id="showPassword"
                name="rememberMe"
                label={t("remember_me")}
                className={classes.checkBoxInput}
                // isCircle
                onClick={() => setPasswordVisible((prevState) => !prevState)}
              />
              <span
                className={classes.recoverPassword}
                onClick={() => setShowForgotPassword(true)}
                // onClick={resetPasswordClickHandler}
              >
                {t("forgot_password")}?
              </span>
            </div>
            <div className={classes.recaptchaContent}>
              {recaptchaCheckLoading && (
                <div className={classes.checking}>
                  <LoadingOutlined
                    style={{ width: "14px", color: "blue", fontWeight: "700" }}
                  />
                  Verifying reCAPTCHA...
                </div>
              )}
              {!recaptchaCheckLoading && recaptchaVerifySuccess && (
                <div className={classes.checkSuccess}>
                  <CheckCircleOutlined
                    style={{ width: "16px", fontWeight: "700" }}
                  />
                  reCAPTCHA verification successful!
                </div>
              )}
              {!recaptchaCheckLoading && recaptchaVerifySuccess == false && (
                <div className={classes.checkFailed}>
                  <CloseCircleOutlined
                    style={{ width: "16px", marginRight: "5px", color: "red" }}
                  />
                  reCAPTCHA verification failed!
                  <span
                    className={classes.tryAgain}
                    onClick={() => recaptchaVerification()}
                  >
                    Try Again
                  </span>
                </div>
              )}
            </div>
            <div className={classes.buttonContainer}>
              <button className={classes.submitButton} disabled={loading}>
                {loading && (
                  <LoadingOutlined
                    style={{ width: "15px", marginRight: "5px" }}
                  />
                )}
                {t("submit")}
              </button>
            </div>
            <div className={classes.orContainer}>
              <div className={classes.orDiv}></div>
              <p>{t("or")}</p>
              <div className={classes.orDiv}></div>
            </div>
            <div
              className={classes.otherSignIn}
              onClick={() => signInWithGoogle()}
            >
              {googleLoading && <LoadingOutlined style={{ width: "15px" }} />}
              {!googleLoading && (
                <img src="/news-img/icons/google-icon.svg" alt="" />
              )}
              {!googleLoading && <p>{t("continue_with_google")}</p>}
            </div>
            <div
              className={classes.otherSignIn}
              onClick={() => setShowLoginWithEmail(true)}
            >
              <img src="/news-img/icons/email.svg" alt="" />
              <p>{t("login_with_email")}</p>
            </div>
            <div
              className={classes.otherSignIn}
              onClick={() => signInWithFacebook()}
            >
              {facebookLoading && <LoadingOutlined style={{ width: "15px" }} />}
              {!facebookLoading && (
                <img src="/news-img/icons/facebook-icon.svg" alt="" />
              )}
              {!facebookLoading && <p>{t("continue_with_facebook")}</p>}
            </div>

            <section className={classes.signinFooter}>
              <p>
                {t("dont_have_an_account")} ?
                <span className={classes.signUpLink} onClick={shiftToSignup}>
                  &nbsp;{t("sign_up")}
                </span>
              </p>
            </section>
          </form>
        </>
      )}
      {showForgotPassword && (
        <div className={classes.signinForm}>
          <h1>{t("forgot_password")}</h1>
          <Form
            layout="vertical"
            form={form}
            name="filter_form"
            className="ant-advanced-search-form w-100 connectForm"
          >
            <Form.Item
              label={t("registered_email")}
              name="email"
              rules={rules.email}
              style={{ marginTop: "20px" }}
            >
              <Input
                placeholder={t("registered_email")}
                // className="signInInput"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                // onBlur={checkEmailValid}
              />
            </Form.Item>
          </Form>
          <div className={classes.buttonContainer}>
            <button className={classes.submitButton} onClick={() => goBack()}>
              {t("go_back")}
            </button>
            <button
              className={classes.submitButton}
              disabled={loadingForgotPassword}
              onClick={() => forgotPasswordSendLink()}
            >
              {loadingForgotPassword && (
                <LoadingOutlined
                  style={{ width: "15px", marginRight: "5px" }}
                />
              )}
              {t("submit")}
            </button>
          </div>
        </div>
      )}
      {showLoginWithEmail && (
        <div className={classes.signinForm}>
          <h1>{t("login_with_email")}</h1>
          <Form
            layout="vertical"
            form={emailForm}
            name="filter_form"
            className="ant-advanced-search-form w-100 connectForm"
          >
            <Form.Item
              label={t("registered_email")}
              name="email"
              rules={rules.email}
              style={{ marginTop: "20px" }}
            >
              <Input
                placeholder={t("registered_email")}
                // className="signInInput"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                // onBlur={checkEmailValid}
              />
            </Form.Item>
          </Form>
          <div className={classes.buttonContainer}>
            <button className={classes.submitButton} onClick={() => goBack()}>
              {t("go_back")}
            </button>
            <button
              className={classes.submitButton}
              disabled={loadingEmailLoginLink}
              onClick={() => sendLoginWithEmailLink()}
            >
              {loadingEmailLoginLink && (
                <LoadingOutlined
                  style={{ width: "15px", marginRight: "5px" }}
                />
              )}
              {t("submit")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInForm;
