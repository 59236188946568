import { useQuery } from "@tanstack/react-query";
import { PublicInformation } from "helpers/queriesDataKeys";
import {
  API_BASE_URL,
  APP_NAME,
  DONATE_LINK,
  LIVE_URL,
  REACT_APP_DEV_MODE,
  TEST_URL,
  TEST_USER_URL,
} from "configs/AppConfig";

import classes from "./Footer.module.css";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FaqList = [{ name: "FAQ", to: "/faq" }];
const ConnectNavList = [{ name: "Contact Us", to: "/contact-us" }];

const Footer = ({ direction }) => {
  const language =
    JSON.parse(localStorage.getItem(`${APP_NAME}_language`))?.language || "en";

  const user = useSelector((state) => state.user);
  const [categoryData, setCategoryData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const constants = useSelector((state) => state.constants);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { settings } = useSelector((state) => state.constants);

  const footer_pages = [
    { name: t("home"), link: "" },
    {
      name: t("articles"),
      // link: "/advanced-search-article?DataType=article&storyStatus=live",
      subMenu: [
        {
          name: t("articles"),
          link: "/advanced-search-article?DataType=article&storyStatus=live",
        },
        { name: t("add_article"), link: "/my-account/add-article" },
        {
          name: t("historical_articles"),
          link: "/advanced-search-historical?DataType=article&storyStatus=historical",
        },
      ],
    },
    {
      name: t("fact_checked_debunked"),
      // link: "/advanced-search-article?DataType=article&storyStatus=live",
      subMenu: [
        {
          name: t("fact_checked_debunked"),
          link: "/fact-checked",
        },
        {
          name: t("add_fact_checked_debunked"),
          link: "/my-account/add-debunk-article",
        },
      ],
    },
    { name: t("categories"), link: "/categories" },
    // { name: t("debunked"), link: "/fact-checked" },
    {
      name: t("gallery"),
      // link: "/gallery",
      subMenu: [
        {
          name: t("image_gallery"),
          link: "/gallery",
        },
        {
          name: t("video_gallery"),
          link: "/video-gallery",
        },
      ],
    },
    // { name: t("about_us"), link: "/about-us" },
    { name: t("contact_us"), link: "/connect-with-us" },
  ];

  const footer_policy = [
    {
      name: t("terms_of_service"),
      link: `/terms-of-service`,
    },
    {
      name: t("information_policy"),
      link: `/privacy-policy`,
    },
    {
      name: t("do_not_sell_my_information"),
      link: `/do-not-sell-my-information`,
    },
  ];

  const footer_connect = [
    {
      icon: "/news-img/icons/whatsapp-icon.svg",
      url: constants?.settings?.whatsappLink,
      name: "whatsapp",
    },
    {
      icon: "/news-img/icons/facebook-icon.svg",
      url: constants?.settings?.facebookLink,
      name: "facebook",
    },
    {
      icon: "/news-img/icons/youtube-icon.svg",
      url: constants?.settings?.youtubeLink,
      name: "youtube",
    },
    {
      icon: "/news-img/icons/ig-icon.svg",
      url: constants?.settings?.instagramLink,
      name: "instagram",
    },
    {
      icon: "/news-img/icons/linkedin-icon.svg",
      url: constants?.settings?.linkedInLink,
      name: "linkedin",
    },
    {
      icon: "/news-img/icons/x-icon.svg",
      url: constants?.settings?.twitterLink,
      name: "twitter",
    },
  ];

  // const fetchcategories = async () => {
  //   const response = await fetch(`${API_BASE_URL}category/get/all/admin`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // Authorization: `Bearer ${user.sessionToken}`,
  //     },
  //     // body: JSON.stringify(),
  //   });

  //   const data = await response.json();
  //   setCategoryData(
  //     data.data.map((item) => ({ label: item.name, value: item._id }))
  //   );
  //   console.log("response", data);
  //   if (!response.ok) {
  //     // message.error(data.message ? data.message : "Invalid Email or Password");
  //     throw new Error(data.message);
  //   }

  //   return data;
  // };

  // const fetchEvents = async () => {
  //   const response = await fetch(`${API_BASE_URL}eventType/get/all`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // Authorization: `Bearer ${user.sessionToken}`,
  //     },
  //     // body: JSON.stringify(),
  //   });

  //   const data = await response.json();
  //   setEventData(data.data);
  //   console.log("response", data);
  //   if (!response.ok) {
  //     // message.error(data.message ? data.message : "Invalid Email or Password");
  //     throw new Error(data.message);
  //   }

  //   return data;
  // };

  // useEffect(() => {
  //   fetchcategories();
  //   fetchEvents();
  // }, [user]);
  // const {
  //   isLoading,
  //   isError,
  //   data: informations,
  //   error,
  // } = useQuery([PublicInformation], async () => {
  //   const response = await fetch(`${API_BASE_URL}information/get/all`);

  //   const data = await response.json();
  //   if (!response.ok) {
  //     throw new Error(data.message);
  //   }

  //   return data;
  // });

  const location = useLocation();
  const isCart = location.pathname.includes("cart");
  console.log("isCart", location.pathname.includes("cart"));
  const isCheckout = location.pathname.includes("checkout");

  useEffect(() => {
    // Load ShareThis buttons
    if (window.__sharethis__) {
      window.__sharethis__.initialize();
    }
  }, [language]);

  const getLink = (name) => {
    switch (name) {
      case "facebook":
        return settings?.facebookLink ? settings?.facebookLink : "";
      case "twitter":
        return settings?.twitterLink ? settings?.twitterLink : "";
      case "youtube":
        return settings?.youtubeLink ? settings?.youtubeLink : "";
      case "instagram":
        return settings?.instagramLink ? settings?.instagramLink : "";
      case "linkedin":
        return settings?.linkedInLink ? settings?.linkedInLink : "";
      case "whatsapp":
        return settings?.whatsappLink ? settings?.whatsappLink : "";

      default:
        return "";
    }
  };

  return (
    <>
      {!(isCart || isCheckout) && (
        <div className={classes.footer}>
          <div dir={direction} className={classes.footerContent}>
            <div className={classes.footerDiv}>
              <img
                src="/news-img/logo-header.svg"
                alt=""
                onClick={() => {
                  if (window.location.pathname === "/") {
                    // If already on the home page, scroll to top
                    window.scrollTo(0, 0);
                  } else {
                    // If not on the home page, navigate to home
                    navigate("/");
                  }
                }}
              />
              <p className={classes.truth}>{t("footer_truth")}</p>
            </div>
            <div className={classes.footerPages}>
              {/* <p className={classes.pagesTitle}>{t("pages")}</p> */}
              {footer_pages.map((item) => (
                <div>
                  <p
                    className={
                      item?.subMenu ? classes.subMenuList : classes.pagesList
                    }
                    onClick={() => {
                      if (item.link) {
                        item.link === "/connect-with-us"
                          ? window.open(`/connect-with-us`, "_blank")
                          : navigate(item.link);
                      }
                    }}
                  >
                    {item.name}
                  </p>
                  {item?.subMenu?.length > 0 && (
                    <ul className={classes.subMenuFlex}>
                      {item?.subMenu?.map((item) => (
                        <li
                          onClick={() => navigate(item.link)}
                          className={classes.subMenuItems}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
            <div className={classes.footerPolicy}>
              {constants?.privacyPolicies?.map((item) => (
                <p
                  onClick={
                    () => window.open(`/informations/${item.slug}`, "_blank")
                    // navigate(`/informations/${item.slug}`)
                  }
                >
                  {item[language]
                    ? item[language]?.title
                    : item["en"]?.title ||
                      item["ar"]?.title ||
                      item["he"]?.title}
                </p>
              ))}
            </div>
            <div className={classes.footerConnect}>
              <div className={classes.connectWithUs}>
                <p>{t("connect_with_us")}</p>
                <div className={classes.shareIcons}>
                  {footer_connect.map(
                    (item) =>
                      getLink(item?.name).length > 0 && (
                        <img
                          onClick={() =>
                            window.open(getLink(item?.name), "_blank")
                          }
                          src={item.icon}
                        />
                      )
                  )}
                </div>
              </div>
              <div className={classes.donate}>
                <p>{t("footer_donate")}</p>
                <button onClick={() => window.open(DONATE_LINK, "_blank")}>
                  {t("donate")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div dir="ltr" className={classes.footerBottom}>
        <p>
          © {new Date().getFullYear()}, {APP_NAME} v1.2.7. All Rights Reserved.
          {/* . All rights reserved. */}
        </p>
        <p
          className={classes.link}
          onClick={() =>
            window.open(
              `${
                REACT_APP_DEV_MODE == "Dev"
                  ? TEST_URL
                  : REACT_APP_DEV_MODE == "userDev"
                  ? TEST_USER_URL
                  : LIVE_URL
              }informations/privacy-policy`,
              "_blank"
            )
          }
        >
          Privacy Policy
        </p>
        <p
          className={classes.link}
          onClick={() =>
            window.open(
              `${
                REACT_APP_DEV_MODE == "Dev"
                  ? TEST_URL
                  : REACT_APP_DEV_MODE == "userDev"
                  ? TEST_USER_URL
                  : LIVE_URL
              }informations/oumuamua-terms-of-service-overview`,
              "_blank"
            )
          }
        >
          Terms of Service
        </p>
      </div>
    </>
  );
};

export default Footer;
