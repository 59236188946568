import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { REACT_APP_DEV_MODE } from './AppConfig';

let firebaseConfig = {}

if (REACT_APP_DEV_MODE == "Dev") {
    firebaseConfig = {
        apiKey: "AIzaSyB871UMAxkauQP66vgzytUEh16avUXTwuw",
        authDomain: "oumuamua-news.firebaseapp.com",
        projectId: "oumuamua-news",
        storageBucket: "oumuamua-news.appspot.com",
        messagingSenderId: "992530620430",
        appId: "1:992530620430:web:3972c5f0f8067340139c5f",
        measurementId: "G-8PC7785HD1"
    };
} else
    if (REACT_APP_DEV_MODE == "userDev") {
        firebaseConfig = {
            apiKey: "AIzaSyDsv5k1nAEyZCHwNDhxBqQwXMcEkzessF0",
            authDomain: "oumuamua-user-test.firebaseapp.com",
            projectId: "oumuamua-user-test",
            storageBucket: "oumuamua-user-test.firebasestorage.app",
            messagingSenderId: "268028757028",
            appId: "1:268028757028:web:ba30f9e5823c911b0404b1"
        };
    } else {
        firebaseConfig = {
            apiKey: "AIzaSyDjDcuvOwGMMvDgWtmXxSwmG3fSV17jK8Y",
            authDomain: "oumuamua-news-live.firebaseapp.com",
            projectId: "oumuamua-news-live",
            storageBucket: "oumuamua-news-live.appspot.com",
            messagingSenderId: "1030475324965",
            appId: "1:1030475324965:web:981715f383d117b73929cc",
            measurementId: "G-0K4DSF0XYK"
        };
    }

const firebaseApp = initializeApp(firebaseConfig);

// Initialize App Check with reCAPTCHA v3
// const appCheck = initializeAppCheck(firebaseApp, {
//     provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST),
//     isTokenAutoRefreshEnabled: true
// });

// Firebase utils
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser;
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

// Add the 'email' scope to request the user's email address
// facebookAuthProvider.addScope('email');
// facebookAuthProvider.addScope('public_profile');

facebookAuthProvider.setCustomParameters({
    'display': 'popup',  // Popup on desktop
    'ref': 'desktop',    // Force desktop mode on mobile
    'plugin_name': 'Oumuamua', // Add plugin_name
    'prompt': 'consent'  // Ensure account selection
});

export {
    auth,
    currentUser,
    googleAuthProvider,
    facebookAuthProvider,
    // appCheck,
};