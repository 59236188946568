import { useCallback, useEffect, lazy, Suspense, useState, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';

import { userActions } from 'store-redux/user-slice';

import { API_BASE_URL, APP_PREFIX_PATH, REACT_APP_DEV_MODE } from 'configs/AppConfig';

import Layout from 'components/layout/Layout';
import LoadingSpinner from 'components/UI/loading-spinner/LoadingSpinner';
import { ConfigProvider, notification } from 'antd';
import { languageActions } from 'store-redux/language-slice';
import i18n from './language-file/i18n'; // Import the initialized i18n instance
// import AdvancedSearch from 'components/page-content/advanced-search/AdvancedSearch';
import { auth } from 'configs/FirebaseConfig';
import { constantsActions } from 'store-redux/constants-slice';
import { signOut } from 'firebase/auth';
// import AdvancedSearchHistorical from 'components/page-content/advanced-search-historical/AdvancedSearchhistorical';
// import AdvancedSearchArticle from 'components/page-content/advanced-search-articles/AdvancedSearchArticle';
// import AdvancedSearchMobile from 'components/page-content/advanced-search-mobile/AdvancedSearchMobile';
import ProtectedRoute from 'components/layout/protected-route/ProtectedRoute';
import { ConstantsDataKey } from 'helpers/queriesDataKeys';


//Define the main pages urls here
const Home = lazy(() => import(`pages/Home`));
const MyAccount = lazy(() => import('components/page-content/my-account/MyAccount'));
const FactCheckDetail = lazy(() => import('components/page-content/factcheck-content/factcheck-detail/FactCheckDetail'));
const ArticleDetail = lazy(() => import('components/page-content/my-account/my-articles/articles-detail/ArticleDetail'));
const ConnectWithUs = lazy(() => import('components/page-content/connect-withus-content/ConnectWithUs'));
const CategoriesList = lazy(() => import('components/page-content/categories-content/CategoriesList'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const PolicyDetail = lazy(() => import('components/page-content/policy-content/PolicyDetail'));
const NoPageFound = lazy(() => import('pages/NoPageFound'));
const FactCheckList = lazy(() => import('components/page-content/factcheck-content/FactCheckList'));
const Login = lazy(() => import('pages/Login'));
const Signup = lazy(() => import('pages/Signup'));
const AdvancedSearchMobile = lazy(() => import('components/page-content/advanced-search-mobile/AdvancedSearchMobile'));
const AdvancedSearchArticle = lazy(() => import('components/page-content/advanced-search-articles/AdvancedSearchArticle'));
const AdvancedSearchHistorical = lazy(() => import('components/page-content/advanced-search-historical/AdvancedSearchhistorical'));
const AdvancedSearch = lazy(() => import('components/page-content/advanced-search/AdvancedSearch'));
const GalleryPage = lazy(() => import('components/page-content/gallery-content/GalleryPage'));
const OptOutForm = lazy(() => import('components/page-content/opt-out-form/OptOutForm'));
const VideoGalleryPage = lazy(() => import('components/page-content/video-gallery-content/VideoGalleryPage'));
const VideoGalleryDetail = lazy(() => import('components/page-content/video-gallery-content/video-gallery-detail/VideoGalleryDetail'));
const MyVideoGalleryDetail = lazy(() => import('components/page-content/my-account/my-video-gallery/video-gallery-detail/MyVideoGalleryDetail'));

let refreshTimer;

const App = () => {
  // access the modalSlice State in the Store Reducer
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn, creatingNewUser } = useSelector((state) => state.user);
  console.log('444 user in app js', user)
  const [checkForAuthorization, setCheckForAuthorization] = useState(false);
  const { language } = useSelector((state) => state.language);
  const [jsonData, setJsonData] = useState();
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const api = REACT_APP_DEV_MODE;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  //language-file lazy loading

  // Lazy loading functions
  function loadArabicTranslations() {
    return import('./language-file/translations/ar.json').then((module) => module.default);
  }

  function loadHebrewTranslations() {
    return import('./language-file/translations/he.json').then((module) => module.default);
  }

  useEffect(() => {
    console.log('function run from app.js')
    // Lazy load Arabic translations when language is changed to Arabic
    if (i18n.language === 'ar') {
      loadArabicTranslations().then((translations) => {
        i18n.addResourceBundle('ar', 'translation', translations, true, true);
      });
    }
    // Lazy load Hebrew translations when language is changed to Hebrew
    else if (i18n.language === 'he') {
      loadHebrewTranslations().then((translations) => {
        i18n.addResourceBundle('he', 'translation', translations, true, true);
      });
    }
  }, [i18n.language]);

  //fetch json file

  console.log('json data is', jsonData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(`${API_BASE_URL}home/generate-file`);

        // const data = await response.json();
        // const fileUrl = data.fileUrl;

        const fileUrl = REACT_APP_DEV_MODE == 'Dev' ? "https://test-oumuamua.s3.us-east-2.amazonaws.com/home-file.txt" : REACT_APP_DEV_MODE == "userDev"
          ? "https://uat-oumuamua.s3.us-west-2.amazonaws.com/home-file.txt" : "https://live-oumuamua.s3.us-east-2.amazonaws.com/home-file.txt";

        // Fetch the text file
        const textResponse = await fetch(fileUrl, {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
          },
          cache: 'no-cache',
        });
        console.log('text response is', textResponse)

        const textData = await textResponse.text();
        const parsedData = JSON.parse(textData);
        console.log('parsed data', parsedData)
        const settingsData = parsedData?.settings[0];
        dispatch(constantsActions.toggleConstants({ settings: settingsData, widgets: parsedData?.widgets, constants: parsedData?.constants, privacyPolicies: parsedData?.privacyPolicies }));

        setJsonData(parsedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  //refresh token logic
  const setTokenRefreshTimer = (user) => {
    user.getIdTokenResult().then((idTokenResult) => {
      const expirationTime = idTokenResult.expirationTime;
      const timeUntilExpiry = new Date(expirationTime).getTime() - new Date().getTime();
      const refreshTime = timeUntilExpiry - 5 * 60 * 1000; // Refresh 5 minutes before expiry

      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }

      refreshTimer = setTimeout(async () => {
        const newToken = await user.getIdToken(true);
        console.log("444 newtoken ", newToken)
        dispatch(userActions.updateAccessToken(newToken));
        setTokenRefreshTimer(user);
      }, refreshTime);
    });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && !creatingNewUser) {       //creatingNewUser - not updating the redux state when new user is created
        const accessToken = await user.getIdToken(true); //for new token
        const idTokenResult = await user.getIdTokenResult();
        console.log("idTokenResult 444", idTokenResult)
        const claims = idTokenResult.claims;
        console.log("user data 333", user)
        console.log("claims data 333", claims)

        const userType = idTokenResult.claims.userType;

        console.log("user token 333", user.accessToken)

        // const userData = await fetch(`${API_BASE_URL}users/UserByFirebaseId`, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${user.accessToken}`,
        //   },
        // })
        // console.log("userData from server 333", userData)

        //logging out the user if the userType is not customer, if once user claims are present
        if (userType && userType != "Customer") {
          await signOut(auth);
          dispatch(userActions.logout());
          return;
        }
        const userDetails = {
          displayName: user.displayName,
          email: user.email,
          accessToken: user.accessToken,
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber || claims.phoneNumber,
          refreshToken: user.refreshToken,
          photoURL: user.photoURL || claims.picture,
          uid: user.uid,
          firstName: claims.firstName || user.displayName,
          lastName: claims.lastName,
          middleName: claims.middleName,
          customerType: claims.customerType,
        };
        dispatch(userActions.login({ user: userDetails, accessToken: accessToken }));
        setIsAuthLoading(false);

        setTokenRefreshTimer(user);
      } else {
        dispatch(userActions.logout());
        setIsAuthLoading(false);
        if (refreshTimer) {
          clearTimeout(refreshTimer);
        }
      }
    });
    setCheckForAuthorization(true);
    return () => {
      unsubscribe();
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    };
  }, [dispatch]);

  const grantLoggedInAccess = checkForAuthorization && isLoggedIn;
  const preventAccess = checkForAuthorization && !isLoggedIn;
  console.log("grantLoggedInAccess, preventAccess 222", grantLoggedInAccess, preventAccess)
  //----------------------------------------------------------------------



  const autoLanguageHandler = useCallback(() => {
    dispatch(languageActions.autoFillLanguage());
  }, [dispatch]);

  useEffect(() => {

    // auto fill of location details
    autoLanguageHandler();
  }, [autoLanguageHandler]);



  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleLogout = async () => {
    await signOut(auth);
    dispatch(userActions.logout());
    navigate("/");
    notification.error({
      message: "Error",
      description: "Unauthorized.",
    });
    return;
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        retryDelay: 2000,
        onError: (error) => {
          if (error.response?.status === 401) {
            handleLogout();
          }
        }
      },
      mutations: {
        onError: (error) => {
          if (error.response?.status === 401) {
            handleLogout();
          }
        }
      }
    }
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          // colorPrimary: '#FF6666',
          colorPrimary: '#F01E29',
          colorTextPlaceholder: "#3D3D3D"
          // borderRadius: 2,

          // Alias Token
          // colorBgContainer: '#f6ffed',
        },
        components: {
          Select: {
            optionSelectedBg: '#F01E29', // Background color of selected option
            optionSelectedColor: '#fff', // Text color of selected option
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Layout
          isDashboard={
            location.pathname.includes('dashboard') ||
            location.pathname.includes('profile')
          }
          notScrollable={modalIsVisible}
          isAuthLoading={isAuthLoading}
        >

          {isAuthLoading ? (
            <LoadingSpinner />
          ) : (

            <Routes>
              {/* Define Routes and corresponding components of main pages */}
              <Route
                path={`${APP_PREFIX_PATH}`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/login`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/signup`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <Signup />
                  </Suspense>
                }
              />
              {/* {grantLoggedInAccess && ( */}
              <Route
                path={`${APP_PREFIX_PATH}/my-account`}
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Suspense fallback={<LoadingSpinner />}>
                      <MyAccount />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              {/* )} */}
              <Route
                path={`${APP_PREFIX_PATH}/gallery`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <GalleryPage />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/video-gallery`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <VideoGalleryPage />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/video-gallery/detail/:videoSlug`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <VideoGalleryDetail />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/advanced-search`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdvancedSearch />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/advanced-search-historical`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdvancedSearchHistorical />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/advanced-search-mobile`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdvancedSearchMobile />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/advanced-search-article`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdvancedSearchArticle />
                  </Suspense>
                }
              />
              {/* {grantLoggedInAccess && ( */}
              <Route
                path={`${APP_PREFIX_PATH}/my-account/:pageName/:slugValue`}
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Suspense fallback={<LoadingSpinner />}>
                      <MyAccount />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              {/* )} */}
              {/* {grantLoggedInAccess && ( */}
              <Route
                path={`${APP_PREFIX_PATH}/my-account/:pageName`}
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Suspense fallback={<LoadingSpinner />}>
                      <MyAccount />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              {/* )} */}
              {/* {grantLoggedInAccess && ( */}
              <Route
                path={`${APP_PREFIX_PATH}/my-account/:pageName/article-detail/:articleSlug`}
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Suspense fallback={<LoadingSpinner />}>
                      <ArticleDetail showAsPreview />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              {/* )} */}
              <Route
                path={`${APP_PREFIX_PATH}/my-account/:pageName/video-gallery-detail/:videoSlug`}
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Suspense fallback={<LoadingSpinner />}>
                      <MyVideoGalleryDetail />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/article-detail/:articleSlug`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <FactCheckDetail />
                    {/* <Details /> */}
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/fact-checked`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <FactCheckList />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/opt-out-form`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <OptOutForm />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/fact-checked/detail/:articleSlug`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <FactCheckDetail />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/informations/:infoSlug`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <PolicyDetail />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/connect-with-us`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <ConnectWithUs />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/categories`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <CategoriesList />
                  </Suspense>
                }
              />
              <Route
                path={`${APP_PREFIX_PATH}/forgot-password`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <ForgotPassword />
                  </Suspense>
                }
              />

              {/* Redirect Route */}
              {/* {checkForAuthorization && (
            <Route
              path="*"
              element={<Navigate to={`${APP_PREFIX_PATH}`} replace />}
            />
          )} */}

              {/* Catch-all route for 404 Not Found */}
              <Route
                path="*"
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <NoPageFound />
                  </Suspense>
                }
              />
            </Routes>
          )}
        </Layout>
      </QueryClientProvider >
    </ConfigProvider>
  );
};

export default App;
